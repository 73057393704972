<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter un partenaire
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier un partenaire
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <h3
                  class="card-title ml-5 mt-3"
                  v-if="$route.meta.mode !== 'update'"
                >
                  Informations utilisateur
                </h3>
                <div class="card-body" v-if="$route.meta.mode !== 'update'">
                  <div class="form-group row">
                    <div class="col-lg-3">
                      <label>Nom:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.user.nom"
                        :class="{ 'is-invalid': errors['user.nom'] }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.nom']"
                        >{{ errors["user.nom"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Prénoms</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.user.prenoms"
                        :class="{ 'is-invalid': errors['user.prenoms'] }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.prenoms']"
                        >{{ errors["user.prenoms"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="form.user.email"
                        :class="{ 'is-invalid': errors['user.email'] }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.email']"
                        >{{ errors["user.email"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Sexe</label>
                      <select
                        v-model="form.user.sexe"
                        class="form-control"
                        :class="{ 'is-invalid': errors['user.sexe'] }"
                      >
                        <option value="">Sélectionnez sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.sexe']"
                        >{{ errors["user.sexe"][0] }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <div class="col-lg-3" v-if="$route.meta.mode !== 'update'">
                      <label>Mot de passe</label>
                      <div class="form-inline-view">
                        <input
                          type="password"
                          class="form-control"
                          ref="password"
                          v-model="form.user.password"
                          :class="{ 'is-invalid': errors['user.password'] }"
                        />
                        <span class="input-eyes">
                          <i
                            class="fas fa-eye"
                            v-show="eyeStates === 'open'"
                            @click.prevent.stop="showRegisterPassword"
                          ></i>
                          <i
                            class="fas fa-eye-slash"
                            v-show="eyeStates === 'close'"
                            @click.prevent.stop="hideRegisterPassword"
                          ></i>
                        </span>
                      </div>
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.password']"
                        >{{ errors["user.password"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Confirmation</label>
                      <div class="form-inline-view">
                        <input
                          type="password"
                          class="form-control"
                          ref="confirmPassword"
                          v-model="form.user.password_confirmation"
                          :class="{
                            'is-invalid': errors['user.password_confirmation']
                          }"
                        />
                        <span class="input-eyes">
                          <i
                            class="fas fa-eye"
                            v-show="confirmEyeStates === 'open'"
                            @click.prevent.stop="showRegisterConfirmPassword"
                          ></i>
                          <i
                            class="fas fa-eye-slash"
                            v-show="confirmEyeStates === 'close'"
                            @click.prevent.stop="hideRegisterConfirmPassword"
                          ></i>
                        </span>
                      </div>
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.password_confirmation']"
                        >{{ errors["user.password_confirmation"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Téléphone (+ l'indicatif)</label>
                      <input
                        type="tel"
                        class="form-control"
                        id="phone"
                        v-number-format
                        v-model="form.user.telephone_1"
                        :class="{ 'is-invalid': errors['user.telephone_1'] }"
                        @change="validPhoneNumber"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.telephone_1']"
                        >{{ errors["user.telephone_1"][0] }}</span
                      >
                      <span
                        id="valid-msg"
                        class="text-success"
                        v-show="
                          isValidPhoneNumber && !errors['user.telephone_1']
                        "
                        >✓ Valid</span
                      >
                      <span
                        id="error-msg"
                        class="text-danger"
                        v-show="
                          isInvalidPhoneNumber && !errors['user.telephone_1']
                        "
                        >{{ inValidError }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Autre Téléphone</label>
                      <input
                        type="tel"
                        id="otherPhone"
                        class="form-control"
                        v-number-format
                        v-model="form.user.telephone_2"
                        :class="{ 'is-invalid': errors['user.telephone_2'] }"
                        @change="validOtherPhoneNumber"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['user.telephone_2']"
                        >{{ errors["user.telephone_2"][0] }}</span
                      >
                      <span
                        id="valid-msg1"
                        class="text-success"
                        v-show="
                          isValidPhoneNumber1 && !errors['user.telephone_2']
                        "
                        >✓ Valid</span
                      >
                      <span
                        id="error-msg1"
                        class="text-danger"
                        v-show="
                          isInvalidPhoneNumber1 && !errors['user.telephone_2']
                        "
                        >{{ inValidError1 }}</span
                      >
                    </div>
                  </div>
                </div>
                <hr />
                <h3 class="card-title ml-5">Informations partenaire</h3>
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-3">
                      <label>Nom</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.partenaire.nom_partenaire"
                        :class="{
                          'is-invalid': errors['partenaire.nom_partenaire']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.nom_partenaire']"
                        >{{ errors["partenaire.nom_partenaire"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Presentation</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.partenaire.presentation"
                        :class="{
                          'is-invalid': errors['partenaire.presentation']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.presentation']"
                        >{{ errors["partenaire.presentation"][0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label>Ville(s)</label>
                      <multiselect
                        v-model="form.partenaire.villes"
                        :options="option.villes"
                        :multiple="true"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        :allow-empty="false"
                        placeholder="Choisir une ville"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.villes']"
                        >{{ errors["partenaire.villes"][0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label>Catégorie</label>
                      <multiselect
                        v-model="form.partenaire.categorie"
                        :options="option.categories"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir un quartier"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setCategoryId()"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.categorie_id']"
                        >{{ errors["partenaire.categorie_id"][0] }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <div class="col-lg-3">
                      <label>Latitude :</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.partenaire.lat"
                        :class="{ 'is-invalid': errors['partenaire.lat'] }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.lat']"
                        >{{ errors["partenaire.lat"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Longitude</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.partenaire.long"
                        :class="{ 'is-invalid': errors['partenaire.long'] }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.long']"
                        >{{ errors["partenaire.long"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Adresse</label>
                      <textarea
                        class="form-control"
                        v-model="form.partenaire.adresse"
                        :class="{ 'is-invalid': errors['partenaire.adresse'] }"
                        row="3"
                      ></textarea>
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.adresse']"
                        >{{ errors["partenaire.adresse"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label class="btn btn-primary"
                        >Ajouter image
                        <input
                          id="customFile"
                          ref="file"
                          @change="handleFileObject"
                          type="file"
                          accept="image/*"
                          class="form-control-file"
                          :class="{
                            'is-invalid': errors['cover_image']
                          }"
                        />
                      </label>
                      <span
                        class="form-text text-danger"
                        v-if="errors['cover_image']"
                        >{{ errors["cover_image"][0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <hr />
                <h3 class="card-title ml-5">Informations sur horaire</h3>
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-3">
                      <label>Jour ouverture 1</label>
                      <select
                        class="form-control"
                        v-model="form.partenaire.jour_ouverture_1"
                        :class="{
                          'is-invalid': errors['partenaire.jour_ouverture_1']
                        }"
                      >
                        <option value="">Selectionner le jour</option>
                        <option
                          v-for="(day, index) in days"
                          :key="index"
                          :value="day.name"
                        >
                          {{ day.name }}
                        </option>
                      </select>
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.jour_ouverture_1']"
                        >{{ errors["partenaire.jour_ouverture_1"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Jour ouverture 2</label>
                      <select
                        class="form-control"
                        v-model="form.partenaire.jour_ouverture_2"
                        :class="{
                          'is-invalid': errors['partenaire.jour_ouverture_2']
                        }"
                      >
                        <option value="">Selectionner le jour</option>
                        <option
                          v-for="(day, index) in days"
                          :key="index"
                          :value="day.name"
                        >
                          {{ day.name }}
                        </option>
                      </select>
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.jour_ouverture_2']"
                        >{{ errors["partenaire.jour_ouverture_2"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Heure debut semaine</label>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.partenaire.heure_debut_week"
                        :class="{
                          'is-invalid': errors['partenaire.heure_debut_week']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.heure_debut_week']"
                        >{{ errors["partenaire.heure_debut_week"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Heure fin semaine</label>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.partenaire.heure_fin_week"
                        :class="{
                          'is-invalid': errors['partenaire.heure_fin_week']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.heure_fin_week']"
                        >{{ errors["partenaire.heure_fin_week"][0] }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <div class="col-lg-3">
                      <label>Heure debut weekend</label>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.partenaire.heure_debut_weekend"
                        :class="{
                          'is-invalid': errors['partenaire.heure_debut_weekend']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.heure_debut_weekend']"
                        >{{ errors["partenaire.heure_debut_weekend"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Heure fin weekend</label>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.partenaire.heure_fin_weekend"
                        :class="{
                          'is-invalid': errors['partenaire.heure_fin_weekend']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.heure_fin_weekend']"
                        >{{ errors["partenaire.heure_fin_weekend"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Heure pause debut</label>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.partenaire.heure_pause_debut"
                        :class="{
                          'is-invalid': errors['partenaire.heure_pause_debut']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.heure_pause_debut']"
                        >{{ errors["partenaire.heure_pause_debut"][0] }}</span
                      >
                    </div>
                    <div class="col-lg-3">
                      <label>Heure pause fin</label>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.partenaire.heure_pause_fin"
                        :class="{
                          'is-invalid': errors['partenaire.heure_pause_fin']
                        }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors['partenaire.heure_pause_fin']"
                        >{{ errors["partenaire.heure_pause_fin"][0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button
                        type="submit"
                        ref="kt_login_signin_submit"
                        class="btn btn-primary btn-outline-primary btn-primary mr-2"
                      >
                        Enregistrer
                      </button>
                      <button
                        type="reset"
                        class="btn btn-secondary btn-outline-secondary btn-secondary"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "Form",
  data() {
    return {
      initialize: "partener/create",
      store: "partener",
      method: "post",
      form: {
        user: {},
        partenaire: {}
      },
      option: {
        villes: [],
        categories: []
      },
      days: [
        { name: "Lundi" },
        { name: "Mardi" },
        { name: "Mercredi" },
        { name: "Jeudi" },
        { name: "Vendredi" },
        { name: "Samedi" },
        { name: "Dimanche" }
      ],
      success: false,
      has_error: false,
      error: "",
      errors: [],
      cover_image: null,
      cover_image_name: null,
      formattedNum1: null,
      formattedNum2: null,
      isValidPhoneNumber: false,
      isInvalidPhoneNumber: false,
      inValidError: "",
      isValidPhoneNumber1: false,
      isInvalidPhoneNumber1: false,
      inValidError1: "",
      eyeStates: "open",
      confirmEyeStates: "open"
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "partener/" + this.$route.params.id + "/edit";
      this.store = "partener/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  mounted() {
    const input = document.querySelector("#phone");
    const input2 = document.querySelector("#otherPhone");
    this.formattedNum1 = this.inputInternational(input);
    this.formattedNum2 = this.inputInternational(input2);
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
        vm.option = response.data.option;
      });
    },
    validPhoneNumber(e) {
      if (this.checkPhoneNumber(this.formattedNum1)) {
        e.className = "is-valid";
        this.isValidPhoneNumber = true;
        this.isInvalidPhoneNumber = false;
      } else {
        e.className = "is-invalid";
        this.inValidError =
          "Numéro de téléphone invalide saisir un numéro correct svp.";
        this.isValidPhoneNumber = false;
        this.isInvalidPhoneNumber = true;
      }
    },
    validOtherPhoneNumber(e) {
      if (this.checkPhoneNumber(this.formattedNum2)) {
        e.className = "is-valid";
        this.isValidPhoneNumber1 = true;
        this.isInvalidPhoneNumber1 = false;
      } else {
        e.className = "is-invalid";
        this.inValidError1 =
          "Numéro de téléphone invalide saisir un numéro correct svp.";
        this.isValidPhoneNumber1 = false;
        this.isInvalidPhoneNumber1 = true;
      }
    },
    setCategoryId() {
      this.form.partenaire.categorie_id = this.form.partenaire.categorie.id;
    },
    save() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      this.form.user.telephone_1 = this.checkPhoneNumber(this.formattedNum1);
      this.form.user.telephone_2 = this.checkPhoneNumber(this.formattedNum2);
      let vm = this;
      let formData = new FormData();
      formData.append("cover_image", this.cover_image);
      if (this.$route.meta.mode === "create") {
        Object.entries(this.form.user).forEach(([key, value]) => {
          formData.append("user[" + key + "]", value);
        });
      }
      Object.entries(this.form.partenaire).forEach(([key, value]) => {
        formData.append("partenaire[" + key + "]", value);
        if (key === "villes") {
          formData.append("partenaire[" + key + "]", JSON.stringify(value));
        }
      });

      formData.append("cover_image", this.cover_image);
      if (this.$route.meta.mode === "update") {
        formData.append("_method", "PUT");
      }
      let config = {
        headers: {
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random()
              .toString()
              .substr(2)
        }
      };
      ApiService[this.method](this.store, formData, config)
        .then(function(response) {
          if (response.data.success) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    },
    handleFileObject() {
      this.cover_image = this.$refs.file.files[0];
      this.cover_image_name = this.cover_image.name;
    },
    showRegisterPassword() {
      this.$refs.password.type = "text";
      this.eyeStates = "close";
    },
    hideRegisterPassword() {
      this.$refs.password.type = "password";
      this.eyeStates = "open";
    },
    showRegisterConfirmPassword() {
      this.$refs.confirmPassword.type = "text";
      this.confirmEyeStates = "close";
    },
    hideRegisterConfirmPassword() {
      this.$refs.confirmPassword.type = "password";
      this.confirmEyeStates = "open";
    }
  }
};
</script>

<style scoped></style>
